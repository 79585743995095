import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    contentHeaderWrapper: {
        paddingBottom: theme.spacing(15),
    },
    headerIllustrationWrapper: {
        position: 'relative',
        bottom: -50,
        maxWidth: 600,
        margin: theme.spacing(-5, 'auto'),
    },
    researchWrapper: {
        marginBottom: theme.spacing(3),
    },
    researchAuthor: {
        fontWeight: 600,
    },
    linkArrowIcon: {
        marginLeft: theme.spacing(1),
        verticalAlign: 'middle',
    },
}));
