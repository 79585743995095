import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@material-ui/core';

import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import useStyles from './styles';

const query = graphql`
    query aboutIndexPage {
        illustration: file(relativePath: { eq: "page-about-illustration.png" }) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1506, quality: 100, placeholder: NONE)
            }
        }
        flunetDocument: file(name: { eq: "flunet-automatedtracking-of-contacts-during-flu-season" }) {
            publicURL
        }
        iepiDocument: file(name: { eq: "iepi-end-to-end-solution" }) {
            publicURL
        }
        humanNetworkDocument: file(name: { eq: "human-network-data-collection-in-the-wild" }) {
            publicURL
        }
        h1n1Document: file(name: { eq: "leveraging-h1n1-infection-transmission-modeling-with-proximity-sensor-microdata" }) {
            publicURL
        }
        temporalDocument: file(
            name: { eq: "temporal-aggregation-impacts-on-epidemiological-simulations-employing-microcontact-data" }
        ) {
            publicURL
        }
        humanDynamicDocument: file(name: { eq: "human-dynamic-networks-in-opportunistic-routing-and-epidemiology" }) {
            publicURL
        }
    }
`;

const AboutIndexPage = (props) => {
    const { location } = props;

    const classes = useStyles();
    const queryData = useStaticQuery(query);

    const description = `Ethica enables people to convert their smartphone into a micro research lab, so they
                         can join scientists in finding life-changing discoveries.`;
    const researches = [
        {
            title: 'Flunet: Automated tracking of contacts during flu season',
            author: 'MS Hashemian, KG Stanley, ND Osgood',
            description:
                'Published in the Proceedings of the 8th International Symposium on Modeling and Optimization in Mobile, Ad Hoc and Wireless Networks (WiOpt) - 2010',
            url: queryData.flunetDocument.publicURL,
        },
        {
            title: 'iEpi: an end to end solution for collecting, conditioning and utilizing epidemiologically relevant data',
            author: 'MS Hashemian, DL Knowles, J Calver, W Qian, MC Bullock, S Bell, RL Mandryk, ND Osgood, KG Stanley',
            description:
                'Published in the Proceedings of the 2nd ACM international workshop on Pervasive Wireless Healthcare - 2012',
            url: queryData.iepiDocument.publicURL,
        },
        {
            title: 'Human network data collection in the wild: the epidemiological utility of micro-contact and location data',
            author: 'MS Hashemian, KG Stanley, DL Knowles, J Calver, ND Osgood',
            description: 'Published in the Proceedings of the 2nd ACM SIGHIT International Health Informatics Symposium - 2012',
            url: queryData.humanNetworkDocument.publicURL,
        },
        {
            title: 'Leveraging H1N1 infection transmission modeling with proximity sensor microdata',
            author: 'MS Hashemian, KG Stanley, ND Osgood',
            description: 'Published in the BMC Medical Informatics and Decision Making - 2012',
            url: queryData.h1n1Document.publicURL,
        },
        {
            title: 'Temporal aggregation impacts on epidemiological simulations employing microcontact data',
            author: 'MS Hashemian, W Qian, KG Stanley, ND Osgood',
            description: 'Published in the BMC Medical Informatics and Decision Making - 2012',
            url: queryData.temporalDocument.publicURL,
        },
        {
            title: 'Human dynamic networks in opportunistic routing and epidemiology',
            author: 'MS Hashemian',
            description: 'Published in the Electronic Theses and Dissertations Collections, University of Saskatchewan - 2012',
            url: queryData.humanDynamicDocument.publicURL,
        },
    ];

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title="About"
            description={description}
            illustrationImage={queryData.illustration}
            metaImageSrc={getSrc(queryData.illustration)}
            showIllustrationImageOnly
            classes={{
                illustrationWrapper: classes.headerIllustrationWrapper,
                contentHeaderWrapper: classes.contentHeaderWrapper,
            }}
        >
            <Grid container alignItems="flex-start" component="article" direction="column">
                <Typography variant="h2" component="p">
                    {description}
                </Typography>
                <Typography>
                    Smartphones and wearables are powerful tools which have revolutionized our way of life. Beyond the untethered
                    connectivity and casual games, smartphones allow us to objectively measure our behavior through apps such as
                    exercise monitoring tools or food journals. While such information is can help guide us towards positive
                    change, it can also be very valuable to scientists helping them accomplish ground-breaking discoveries.
                    Information from smartphones can help researchers to better understand humans, and they can use this
                    understanding for battle diseases such as the Zika virus or Ebola, to control smoking behavior in teenagers,
                    or to design better public transit services.
                </Typography>
                <Typography>
                    Ethica is the first platform that turns smartphones into micro research labs. It allows the use of almost all
                    smartphone functionalities in research studies, from passive sensor monitoring to controlled tasks, and
                    context-dependent surveys. Researchers can use Ethica to perform their observations more accurately and at
                    larger scale, while putting less burden on participants. The ability to perform eligibility screening,
                    informed consent, and enrolment all through the phone without requiring a physical meeting with participants
                    means anyone from anywhere in the world can join the study, as long as they have a smartphone. This means more
                    participants, and more meaningful data.
                </Typography>
                <Typography>
                    Most importantly, there is no programming expertise needed to use Ethica. The ability of Ethica app to adapt
                    itself to any study design means anyone with any level of expertise in computer science can start using Ethica
                    in minutes.
                </Typography>
                <Typography variant="h2">Our Story</Typography>
                <Typography>
                    Ethica emerged from a research project at the University of Saskatchewan named iEpi. iEpi was originally
                    designed to use sensors for tracking the spread of the H1N1 virus in central Canada during the winter of 2009.
                    After the initial project, iEpi’s success motivated us to continue investigation. Collaborators became
                    interested in using iEpi in areas as diverse as computer game design, measuring intervention effectiveness
                    related to social health determinants, computational social science, automated tracking of infection spread
                    during epidemics.
                </Typography>
                <Typography>
                    Our experience with the diverse applications of iEpi and seeing how it can benefit and accelerate research in
                    different areas motivated us to make it available to other researchers as well. Ethica has enhanced iEpi
                    creating a fully automated research system useable by anyone, anywhere in the world. Ethica has been
                    successfully deployed in projects in North America, Europe, and Australia.
                </Typography>
                <Typography variant="h2">Research behind Ethica</Typography>
                {researches.map((research) => (
                    <Box className={classes.researchWrapper} key={research.title}>
                        <a href={research.url}>
                            <Typography variant="h4" component="div">
                                {research.title}
                                <FontAwesomeIcon icon={['fal', 'long-arrow-right']} className={classes.linkArrowIcon} />
                            </Typography>
                        </a>
                        <Typography display="inline" className={classes.researchAuthor}>
                            Author:&nbsp;
                        </Typography>
                        <Typography variant="body2" display="inline">
                            {research.author}
                        </Typography>
                        <br />
                        <Typography variant="body2">{research.description}</Typography>
                    </Box>
                ))}
            </Grid>
        </BigContentHeaderLayout>
    );
};

AboutIndexPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default AboutIndexPage;
